<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="icon-star"></i> Asset</b-card-title>
			<b-card-sub-title>Manages all of the assets accountable for each company</b-card-sub-title>
			<div fluid class="px-2 mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<AccountableAssets :isSuperAdmin="isSuperAdmin" :isViewer="isViewer" :isAccounting="isAccounting"
					:filterByOptions="filterByOptions" :allAssetTypesObj="allAssetTypesObj" />
			</div>
		</b-card>

		<!-- Modals here -->
		<ActivateAsset :isSuperAdmin="isSuperAdmin" :allAssetTypesObj="allAssetTypesObj"
			:allCompanyOptions="filterByOptions.companyItems"
			:allStorageLocationOptions="filterByOptions.storageLocationItems" />
		<ActivateAssets :isSuperAdmin="isSuperAdmin" :allAssetTypesObj="allAssetTypesObj"
			:allCompanyOptions="filterByOptions.companyItems"
			:allStorageLocationOptions="filterByOptions.storageLocationItems" />

		<MarkAsLostAsset />
		<MarkAsLostAssets />

		<ChangeAssetLocation :companyOptions="filterByOptions.companyItems"
			:allStorageLocationOptions="filterByOptions.storageLocationItems" />
		<ChangeAssetsLocation :companyOptions="filterByOptions.companyItems"
			:allStorageLocationOptions="filterByOptions.storageLocationItems" />


		<AssignConditionOnAssets :allAssetTypesObj="allAssetTypesObj" />
		<UpdateAssetDetails :isSuperAdmin="isSuperAdmin" :isAccounting="isAccounting"
			:allAssetTypesObj="allAssetTypesObj" :allCompanyOptions="filterByOptions.companyItems"
			:allStorageLocationOptions="filterByOptions.storageLocationItems" />

	</div>
</template>

<script>
// Components
import AccountableAssets from '@/views/asset/asset/AccountableAssets';
import ActivateAsset from '@/views/asset/asset/ActivateAsset';
import ActivateAssets from '@/views/asset/asset/ActivateAssets';
import UpdateAssetDetails from '@/views/asset/asset/UpdateAssetDetails';
import MarkAsLostAsset from '@/views/asset/asset/MarkAsLostAsset';
import MarkAsLostAssets from '@/views/asset/asset/MarkAsLostAssets';
import ChangeAssetLocation from '@/views/asset/asset/ChangeAssetLocation';
import ChangeAssetsLocation from '@/views/asset/asset/ChangeAssetsLocation';
import AssignConditionOnAssets from '@/views/asset/asset/AssignConditionOnAssets';

// Util
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// Others
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'assets',
	components: {
		AccountableAssets,
		ActivateAsset,
		ActivateAssets,
		UpdateAssetDetails,
		MarkAsLostAsset,
		MarkAsLostAssets,
		ChangeAssetLocation,
		ChangeAssetsLocation,
		AssignConditionOnAssets,
		Loading,
	},
	data() {
		return {
			allCompaniesObj: {},
			allStorageLocationsObj: {},
			allAssetTypesObj: {},

			filterByOptions: {
				companyItems: [],
				connectionItems: [],
				storageLocationItems: [],
				assetTypeItems: [],
			},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isAccounting: this.$store.getters.isAccounting,
			isViewer: this.$store.getters.isViewer || this.$store.getters.isApprover || this.$store.getters.isMaintenance,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	mounted() {
		setTimeout(() => {
			try {
				// Show Loader
				this.isLoading = true;

				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.allStorageLocationsObj = { ...this.$store.getters.storageLocations };
				this.allAssetTypesObj = { ...this.$store.getters.assetTypes };

				let companyItems = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
				this.filterByOptions.companyItems = companyItems;

				let storageLocationItems = DropDownItemsUtil.retrieveStorageLocations(this.allStorageLocationsObj);
				this.filterByOptions.storageLocationItems = storageLocationItems;

				let assetTypeItems = DropDownItemsUtil.retrieveAssetTypes(this.allAssetTypesObj, true);
				this.filterByOptions.assetTypeItems = assetTypeItems;

			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}, config.timeout);
	},
	methods: {},
};
</script>